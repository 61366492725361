import React from 'react'

function SkillItem({source}) {
  return (
    <img
        src={source}

    />
  )
}

export default SkillItem